import { HyperionPersonaConfig } from '@cognitiv/galaxy-api';

export const hyperion_persona_config_default: HyperionPersonaConfig = {
  persona_config_id: 0,
  user_profile_config_id: 0,
  min_url_count: 50,
  min_url_count_formatted: '50',
  max_url_count: 1024,
  max_url_count_formatted: '1,024',
  identity_level_id: 1,
  identity_level_name: 'IID',
  approved_persona_config_version_id: 0,
  event_source_id: 0,
  event_source_name: '',
  event_source_type_id: 0,
  event_source_type_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  user_profile_prompt_id: 0,
  user_profile_prompt_template: '',
  user_profile_model_id: 1,
  user_profile_model_name: 'gpt-4o-mini',
  user_profile_format_id: 1,
  user_profile_format_name: 'UserProfile20241028',
  embedding_id: 1,
  embedding_name: 'text-embedding-3-small',
  embedding_dimensions: 128,
  embedding_dimensions_formatted: '128',
  embedding_model_id: 0,
  name_prompt_id: 0,
  name_prompt_template: '',
  short_description_prompt_id: 0,
  short_description_prompt_template: '',
  description_prompt_id: 0,
  description_prompt_template: '',
  location_prompt_id: 0,
  location_prompt_template: '',
  occupation_prompt_id: 0,
  occupation_prompt_template: '',
  financial_prompt_id: 0,
  financial_prompt_template: '',
  shopping_prompt_id: 0,
  shopping_prompt_template: '',
  interest_prompt_id: 0,
  interest_prompt_template: '',
  personality_prompt_id: 0,
  personality_prompt_template: '',
  demographic_prompt_id: 0,
  demographic_prompt_template: '',
  difference_prompt_id: 0,
  difference_prompt_template: '',
  persona_profile_format_id: 2,
  persona_profile_format_name: 'PersonaProfile20241028',
  persona_model_id: 2,
  persona_model_name: 'gpt-4o-2024-08-06',
  persona_count: 20,
  persona_count_formatted: '20',
  positive_user_count: 10000,
  positive_user_count_formatted: '10,000',
  negative_user_count: 10000,
  negative_user_count_formatted: '10,000',
  interest_cluster_count: 20,
  interest_cluster_count_formatted: '20',
  user_cluster_count: 20,
  user_cluster_count_formatted: '20',
  is_active: false,
  created_by: '',
  created_date: '',
  persona_config_name: '',
  created_date_formatted: '',
  uuid: '',
};
