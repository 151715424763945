import { HyperionPersonaConfig } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_persona_config_default } from 'products/hyperion/operators/persona-config/defaults';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionPersonaConfig = { ...hyperion_persona_config_default };

export const hyperionPersonaConfigSlice = createSlice({
  name: SLICE.HYPERION_PERSONA_CONFIG,
  initialState,
  reducers: {
    clearHyperionPersonaConfig: () => initialState,
    setHyperionPersonaConfig: (_, action: PayloadAction<HyperionPersonaConfig>) => action.payload,
  },
});

export const { clearHyperionPersonaConfig, setHyperionPersonaConfig } = hyperionPersonaConfigSlice.actions;

export default hyperionPersonaConfigSlice.reducer;
