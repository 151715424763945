import { LibraDspSeatIdentifierAPI } from '@cognitiv/galaxy-api';

type Storage = 'user' | 'settings' | 'confirm_orion_relevance' | 'confirm_hyperion_report_type';
type Store = { [key: string]: any } | any[] | boolean;

export const getActivePage = (page: string) => {
  return String(window.location.pathname).toLowerCase().includes(page);
};

export const getLocalStorage = (item: Storage = 'user') => {
  let store = null;
  const stored_item = localStorage.getItem(item);
  if (stored_item) {
    store = JSON.parse(stored_item);
  }
  if (store) {
    return store;
  }
  return {};
};

export const setLocalStorage = (item: Storage = 'user', store: Store) => {
  const data = JSON.stringify(store);
  localStorage.setItem(item, data);
};

export const deleteLocalStorage = (item: Storage = 'user') => {
  localStorage.setItem(item, '{}');
};

export const stringEllipsis = ({ value, ellipsis }: { value?: string | null; ellipsis: number }) => {
  const string = typeof value === 'string' ? value : String(value);
  if (string.length > ellipsis) {
    return `${string.substring(0, ellipsis)}...`;
  }
  return string;
};

export const getDspSeatIdentifier = (dsp: Partial<LibraDspSeatIdentifierAPI>): string => {
  const { dsp_name, advertiser_name, dsp_seat_identifier } = dsp;

  if (dsp_name && dsp_seat_identifier) {
    return `${advertiser_name}: ${dsp_name} [${dsp_seat_identifier}]`;
  }
  return '';
};
