import { HyperionPersona } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_persona_default } from 'products/hyperion/operators/persona/defaults';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionPersona = { ...hyperion_persona_default };

export const hyperionPersonaSlice = createSlice({
  name: SLICE.HYPERION_PERSONA,
  initialState,
  reducers: {
    clearHyperionPersona: () => initialState,
    setHyperionPersona: (_, action: PayloadAction<HyperionPersona>) => action.payload,
  },
});

export const { clearHyperionPersona, setHyperionPersona } = hyperionPersonaSlice.actions;

export default hyperionPersonaSlice.reducer;
