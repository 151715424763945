import { HyperionFormat } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionFormat[] = [];

export const hyperionFormatsListSlice = createSlice({
  name: SLICE.HYPERION_FORMATS_LIST,
  initialState,
  reducers: {
    clearHyperionFormatsList: () => {
      return initialState;
    },
    setHyperionFormatsList: (_, action: PayloadAction<HyperionFormat[]>) => action.payload,
  },
});

export const { clearHyperionFormatsList, setHyperionFormatsList } = hyperionFormatsListSlice.actions;

export default hyperionFormatsListSlice.reducer;
