import { HyperionModel } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionModel[] = [];

export const hyperionModelsListSlice = createSlice({
  name: SLICE.HYPERION_MODELS_LIST,
  initialState,
  reducers: {
    clearHyperionModelsList: () => {
      return initialState;
    },
    setHyperionModelsList: (_, action: PayloadAction<HyperionModel[]>) => action.payload,
  },
});

export const { clearHyperionModelsList, setHyperionModelsList } = hyperionModelsListSlice.actions;

export default hyperionModelsListSlice.reducer;
