export enum SLICE {
  HYPERION_CS_CAMPAIGN_PERFORMANCE_TRACKER = 'hyperion-cs-campaign-performance-tracker',
  HYPERION_DS_CAMPAIGN_PERFORMANCE_TRACKER = 'hyperion-ds-campaign-performance-tracker',
  HYPERION_DSP_LINE_ITEM_DEAL_MAPPINGS = 'hyperion-dsp-line-item-deal-mappings',
  HYPERION_EVENT_SOURCE_PERFORMANCE_SUMMARY = 'hyperion-event-source-performance-summary',
  HYPERION_FILTER_TIMESTAMPS = 'hyperion-filter-timestamps',
  HYPERION_IMPORT_CONFIG = 'hyperion-import-config',
  HYPERION_IMPORT_CONFIG_UNJOINED_ROWS = 'hyperion-import-config-unjoined-rows',
  HYPERION_IMPORT_CONFIGS = 'hyperion-import-configs',
  HYPERION_IMPORT_GLOBAL_HEALTH_STATUS_HISTORY = 'hyperion-import-global-health-status-history',
  HYPERION_IMPORT_HEALTH_STATUS_HISTORY = 'hyperion-import-health-status-history',
  HYPERION_INBOUND_REPORTS = 'hyperion-inbound-reports',
  HYPERION_INTERVALS_LIST = 'hyperion-interval-types-list',
  HYPERION_OPERATOR_TYPES = 'hyperion-operator-types',
  HYPERION_OUTPUT_TYPES_LIST = 'hyperion-output-types-list',
  HYPERION_REPORT_CASCADE_SORTINGS = 'hyperion-report-cascade-sortings',
  HYPERION_REPORT_SCHEDULES = 'hyperion-report-schedules',
  HYPERION_REPORT_TYPES = 'hyperion-report-types',
  HYPERION_REPORTING = 'hyperion-reporting',
  HYPERION_REPORTS = 'hyperion-reports',
  HYPERION_PERSONA_CONFIG = 'hyperion-persona-config',
  HYPERION_PERSONA_CONFIGS = 'hyperion-persona-configs',
  HYPERION_PERSONAS = 'hyperion-personas',
  HYPERION_PERSONA = 'hyperion-persona',
  HYPERION_PERSONA_CONFIG_VERSION = 'hyperion-persona-config-version',
  HYPERION_PERSONA_CONFIG_VERSIONS = 'hyperion-persona-config-versions',
  HYPERION_IDENTITY_LEVELS_LIST = 'hyperion-identity-levels-types-list',
  HYPERION_MODELS_LIST = 'hyperion-models-types-list',
  HYPERION_FORMATS_LIST = 'hyperion-formats-types-list',
}
