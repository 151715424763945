import { HyperionPersona } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionPersona[] = [];

export const hyperionPersonasSlice = createSlice({
  name: SLICE.HYPERION_PERSONAS,
  initialState,
  reducers: {
    clearHyperionPersonas: () => initialState,
    setHyperionPersonas: (_, action: PayloadAction<HyperionPersona[]>) => action.payload,
  },
});

export const { clearHyperionPersonas, setHyperionPersonas } = hyperionPersonasSlice.actions;

export default hyperionPersonasSlice.reducer;
