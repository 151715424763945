import React, { useEffect } from 'react';
import { batch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useClientContext } from '@cognitiv/galaxy-api';
import { selectSettings } from 'ducks/settings/selectors';
import { setHyperionFormatsList } from 'products/hyperion/operators/formats-list/slices';
import { setHyperionIdentityLevelsList } from 'products/hyperion/operators/identity-levels-list/slices';
import { setHyperionModelsList } from 'products/hyperion/operators/models-list/slices';
import { setTitanAdvertisers } from 'products/titan/operators/advertisers/slices';
import { setTitanEventSources } from 'products/titan/operators/event-sources/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Content } from 'components/layout/content/Content';
import { Menu } from 'components/menu/Menu';

import cn from 'products/hyperion/pages/personas/template/PersonasTemplate.module.scss';

export default () => {
  const dispatch = useAppDispatch();
  const { hyperion } = useAppSelector(selectSettings);
  const { Hyperion, Titan, handleError } = useClientContext();

  useEffect(() => {
    const getData = async () => {
      try {
        if (!hyperion) return;

        const [titan_advertisers, titan_event_sources, hyperion_identity_levels_list, hyperion_models_list, hyperion_formats_list] =
          await Promise.all([
            Titan.getTitanAdvertisers(),
            Titan.getTitanEventSources(),
            Hyperion.getHyperionIdentityLevelsList(),
            Hyperion.getHyperionModelsList(),
            Hyperion.getHyperionFormatsList(),
          ]);

        batch(() => {
          dispatch(setTitanAdvertisers(titan_advertisers));
          dispatch(setTitanEventSources(titan_event_sources));
          dispatch(setHyperionIdentityLevelsList(hyperion_identity_levels_list));
          dispatch(setHyperionModelsList(hyperion_models_list));
          dispatch(setHyperionFormatsList(hyperion_formats_list));
        });
      } catch (err) {
        handleError(err);
      }
    };
    if (hyperion) {
      getData();
    }
  }, [dispatch, handleError, Titan, Hyperion, hyperion]);

  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Outlet />
      </Content>
    </div>
  );
};
