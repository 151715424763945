import React, { useEffect } from 'react';
import { batch } from 'react-redux';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import { parseQueries } from '@cognitiv/cicada-ui';
import { useClientContext } from '@cognitiv/galaxy-api';
import { selectSettings } from 'ducks/settings/selectors';
import { ManageLibraDomainList } from 'products/libra/modals/manage-libra-domain-list/ManageLibraDomainList';
import { ManageLibraDspSeatIdentifier } from 'products/libra/modals/manage-libra-dsp-seat-identifier/ManageLibraDspSeatIdentifier';
import { setTitanAdvertisers } from 'products/titan/operators/advertisers/slices';
import { setTitanDspsList } from 'products/titan/operators/dsps-list/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Content } from 'components/layout/content/Content';
import { Menu } from 'components/menu/Menu';

import cn from 'products/titan/template/ApplicationTemplate.module.scss';

export default () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { update } = parseQueries(search);
  const { libra } = useAppSelector(selectSettings);
  const { Libra, Titan, handleError } = useClientContext();

  useEffect(() => {
    const getData = async () => {
      try {
        const [titan_advertisers, titan_dsps_list] = await Promise.all([Titan.getTitanAdvertisers(), Titan.getTitanDspsList()]);

        batch(() => {
          dispatch(setTitanAdvertisers(titan_advertisers));
          dispatch(setTitanDspsList(titan_dsps_list));
        });
      } catch (err) {
        handleError(err);
      }
    };
    if (libra) {
      getData();
    }
  }, [dispatch, update, Libra, Titan, handleError, libra]);

  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Outlet />
      </Content>
      <ManageLibraDomainList />
      <ManageLibraDspSeatIdentifier />
    </div>
  );
};
