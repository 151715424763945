import { HyperionPersonaConfigVersion } from '@cognitiv/galaxy-api';

export const hyperion_persona_config_version_default: HyperionPersonaConfigVersion = {
  created_date_formatted: '',
  uuid: '',
  persona_config_version_id: 0,
  persona_config_id: 0,
  version_number: 0,
  persona_count: 0,
  persona_count_formatted: '',
  positive_user_count: 0,
  positive_user_count_formatted: '',
  negative_user_count: 0,
  negative_user_count_formatted: '',
  interest_cluster_count: 0,
  interest_cluster_count_formatted: '',
  user_cluster_count: 0,
  user_cluster_count_formatted: '',
  persona_config_version_state_id: 0,
  persona_config_version_state_name: '',
  state_id: 1, // only used for option dropdown to show color
  created_date: '',
};
