import { HyperionIdentityLevel } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionIdentityLevel[] = [];

export const hyperionIdentityLevelsListSlice = createSlice({
  name: SLICE.HYPERION_IDENTITY_LEVELS_LIST,
  initialState,
  reducers: {
    clearHyperionIdentityLevelsList: () => {
      return initialState;
    },
    setHyperionIdentityLevelsList: (_, action: PayloadAction<HyperionIdentityLevel[]>) => action.payload,
  },
});

export const { clearHyperionIdentityLevelsList, setHyperionIdentityLevelsList } = hyperionIdentityLevelsListSlice.actions;

export default hyperionIdentityLevelsListSlice.reducer;
