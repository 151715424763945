import libraDomainListSlice from 'products/libra/operators/domain-list/slices';
import libraDomainListsSlice from 'products/libra/operators/domain-lists/slices';
import libraDspSeatIdentifierSlice from 'products/libra/operators/dsp-seat-identifier/slices';
import libraDspSeatIdentifiersSlice from 'products/libra/operators/dsp-seat-identifiers/slices';

export const libra = {
  libra_domain_list: libraDomainListSlice,
  libra_domain_lists: libraDomainListsSlice,
  libra_dsp_seat_identifier: libraDspSeatIdentifierSlice,
  libra_dsp_seat_identifiers: libraDspSeatIdentifiersSlice,
};
