import { VirgoCampaign } from '@cognitiv/galaxy-api';

export const virgo_campaign_default: VirgoCampaign = {
  campaign_id: 0,
  campaign_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  kpi_type_id: null,
  kpi_type_name: '',
  kpi_unit_id: null,
  kpi_unit_name: '',
  kpi_benchmark: null,
  kpi_benchmark_formatted: '',
  kpi_benchmark_abbr_formatted: '',
  conversion_source_id: null,
  conversion_source_name: '',
  delivery_source_id: null,
  delivery_source_name: '',
  product_type_id: 0,
  product_type_name: '',
  primary_event_source_name_formatted: '',
  primary_event_source_id: null,
  primary_event_source_name: '',
  primary_event_source_type_id: null,
  primary_event_source_type_name: '',
  campaign_state_id: 0,
  campaign_state_name: '',
  campaign_pipeline_state_id: 0,
  campaign_pipeline_state_name: '',
  opportunity_size_usd: null,
  opportunity_size_usd_formatted: '',
  creative_types: '',
  revision_id: 0,
  deal_count: 0,
  deal_count_formatted: '',
  supplemental_event_source_ids: [],
  uuid: '',
};
