import { LibraDspSeatIdentifier } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/libra/operators/slice';

const initialState: LibraDspSeatIdentifier[] = [];

export const libraDspSeatIdentifiersSlice = createSlice({
  name: SLICE.LIBRA_DSP_SEAT_IDENTIFIERS,
  initialState,
  reducers: {
    clearLibraDspSeatIdentifiers: () => {
      return initialState;
    },
    setLibraDspSeatIdentifiers: (_, action: PayloadAction<LibraDspSeatIdentifier[]>) => action.payload,
  },
});

export const { clearLibraDspSeatIdentifiers, setLibraDspSeatIdentifiers } = libraDspSeatIdentifiersSlice.actions;

export default libraDspSeatIdentifiersSlice.reducer;
