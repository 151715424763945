import { HyperionPersonaConfigVersion } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionPersonaConfigVersion[] = [];

export const hyperionPersonaConfigVersionsSlice = createSlice({
  name: SLICE.HYPERION_PERSONA_CONFIG_VERSIONS,
  initialState,
  reducers: {
    clearHyperionPersonaConfigVersions: () => initialState,
    setHyperionPersonaConfigVersions: (_, action: PayloadAction<HyperionPersonaConfigVersion[]>) => action.payload,
  },
});

export const { clearHyperionPersonaConfigVersions, setHyperionPersonaConfigVersions } = hyperionPersonaConfigVersionsSlice.actions;

export default hyperionPersonaConfigVersionsSlice.reducer;
