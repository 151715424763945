import { HyperionPersonaConfigVersion } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_persona_config_version_default } from 'products/hyperion/operators/persona-config-version/defaults';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionPersonaConfigVersion = { ...hyperion_persona_config_version_default };

export const hyperionPersonaConfigVersionSlice = createSlice({
  name: SLICE.HYPERION_PERSONA_CONFIG_VERSION,
  initialState,
  reducers: {
    clearHyperionPersonaConfigVersion: () => initialState,
    setHyperionPersonaConfigVersion: (_, action: PayloadAction<HyperionPersonaConfigVersion>) => action.payload,
  },
});

export const { clearHyperionPersonaConfigVersion, setHyperionPersonaConfigVersion } = hyperionPersonaConfigVersionSlice.actions;

export default hyperionPersonaConfigVersionSlice.reducer;
