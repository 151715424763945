import { LibraDspSeatIdentifier } from '@cognitiv/galaxy-api';
import { createSlice } from '@reduxjs/toolkit';
import { libra_dsp_seat_identifier_default } from 'products/libra/operators/dsp-seat-identifier/default';
import { SLICE } from 'products/libra/operators/slice';

const initialState: LibraDspSeatIdentifier = libra_dsp_seat_identifier_default;

export const libraDspSeatIdentifierSlice = createSlice({
  name: SLICE.LIBRA_DSP_SEAT_IDENTIFIER,
  initialState,
  reducers: {
    clearLibraDspSeatIdentifier: () => {
      return initialState;
    },
    setLibraDspSeatIdentifier: (_, action) => {
      return action.payload;
    },
  },
});

export const { clearLibraDspSeatIdentifier, setLibraDspSeatIdentifier } = libraDspSeatIdentifierSlice.actions;

export default libraDspSeatIdentifierSlice.reducer;
