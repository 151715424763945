import { HyperionPersonaConfig } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionPersonaConfig[] = [];

export const hyperionPersonaConfigsSlice = createSlice({
  name: SLICE.HYPERION_PERSONA_CONFIGS,
  initialState,
  reducers: {
    clearHyperionPersonaConfigs: () => initialState,
    setHyperionPersonaConfigs: (_, action: PayloadAction<HyperionPersonaConfig[]>) => action.payload,
  },
});

export const { clearHyperionPersonaConfigs, setHyperionPersonaConfigs } = hyperionPersonaConfigsSlice.actions;

export default hyperionPersonaConfigsSlice.reducer;
